import { z } from "zod";
import { 
    emailValidator,
    inputMaxLength320CharactersValidator, 
    inputMaxLength50CharactersValidator, 
    isRequired,
    notEmptyValidator, 
    optionSelected,
    passwordValidator,
    passwordComparatorValidator,
    zaPhoneNumberValidator,
    unicodeNameValidator,
    localAndInternationalPhoneNumberValidator,
    isValidDate,
    optionalValidator,
    isChecked
} from "~/utils/validators";
import { 
    ChangePasswordFormValidatorId,
    ContactFormValidatorId, 
    LoginFormValidatorId, 
    SignupFormValidatorId, 
    ForgotPasswordFormValidatorId, 
    ResetPasswordFormValidatorId, 
    NewsletterSubscriptionFormValidatorId, 
    ShareProgrammeFormValidatorId,
    FormGeneralSectionFormValidatorId,
    FormValidatorRuleType ,
    ApplicationClosedFormValidatorId
} from "~/utils/enums"; 
import { Validation } from "~/classes/validation";
import {FieldInfo} from "~/schemas/field-info";

export const useValidations = () => {  
    const contactFormValidation = (contactForm: IContactFormCmd): Validation => {
    
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired];
        const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        const reasonValdiators: Array<Function> = [optionSelected];
        const contactNumberValidators: Array<Function> = [localAndInternationalPhoneNumberValidator];
        const messageValdiators: Array<Function> = [isRequired];
    
        
        let validationRule:IValidationRule = {
            validationRuleId: ContactFormValidatorId.Email,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: contactForm.email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule);
    
        validationRule = {
            validationRuleId: ContactFormValidatorId.Name,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: contactForm.name,
            validators: firstNameValdiators
        };
        validationRuleList.validationRules.push(validationRule);
    
        validationRule = {
            validationRuleId: ContactFormValidatorId.Surname,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: contactForm.surname,
            validators: surnameValdiators
        };
        validationRuleList.validationRules.push(validationRule);
    
        validationRule = {
            validationRuleId: ContactFormValidatorId.Reason,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: contactForm.reason,
            validators: reasonValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ContactFormValidatorId.ContactNumber,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: false,
            sourceValue: contactForm.contactNo,
            validators: contactNumberValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ContactFormValidatorId.Message,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: contactForm.message,
            validators: messageValdiators
        };
        validationRuleList.validationRules.push(validationRule);
        
        
        const validation = new Validation(validationRuleList);
    
        return validation;
    };

    const loginFormValidation = (loginForm: ILoginForm ): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const emailValidators: Array<Function> = [emailValidator, isRequired];
        const passwordValdiators: Array<Function> = [notEmptyValidator];

        let validationRule:IValidationRule = {
            validationRuleId: LoginFormValidatorId.LoginEmail,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: loginForm.email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: LoginFormValidatorId.LoginPassword,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: loginForm.password,
            validators: passwordValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const signupFormValidation = (signupForm: ISignUpForm): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired];
        const passwordValidators: Array<Function> = [passwordValidator];
        const passwordComparators: Array<Function> =[passwordComparatorValidator];
        // const confirmedPasswordValdiators: Array<Function> = [passwordComparatorValidator];
        const confirmedPasswordComparators: Array<Function> = [passwordComparatorValidator];
        const titleValidators: Array<Function> = [optionSelected];
        const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        const contactNumberValidators: Array<Function> = [zaPhoneNumberValidator];
        // const allowMarketingValidators: Array<Function> = [isRequired];
        const acceptedTandCValidators: Array<Function> = [isRequired];

        let validationRule:IValidationRule = {
            validationRuleId: SignupFormValidatorId.SignupEmail,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.SignupPassword,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.password,
            comparatorValue: signupForm.confirmed,
            comparatorRuleId: SignupFormValidatorId.SignupConfirmedPassword,
            validators: passwordValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.SignupPassword,
            validationRuleType: FormValidatorRuleType.Comparator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.password,
            comparatorValue: signupForm.confirmed,
            comparatorRuleId: SignupFormValidatorId.SignupConfirmedPassword,
            validators: passwordComparators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.SignupConfirmedPassword,
            validationRuleType: FormValidatorRuleType.Comparator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.confirmed,
            comparatorValue: signupForm.password,
            comparatorRuleId: SignupFormValidatorId.SignupPassword,
            validators: confirmedPasswordComparators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.Title,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.title,
            validators: titleValidators
        };
        validationRuleList.validationRules.push(validationRule);
    
        validationRule = {
            validationRuleId: SignupFormValidatorId.Name,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.name,
            validators: firstNameValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.Surname,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.surname,
            validators: surnameValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.ContactNumber,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: false,
            sourceValue: signupForm.contactNo,
            validators: contactNumberValidators
        };
        validationRuleList.validationRules.push(validationRule);

        // validationRule = {
        //     validationRuleId: SignupFormValidatorId.AllowMarketing,
        //     mandatory: false,
        //     sourceValue: signupForm.marketing,
        //     validators: allowMarketingValidators
        // };
        // validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.AcceptedTandC,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: signupForm.accepted,
            validators: acceptedTandCValidators
        };
        validationRuleList.validationRules.push(validationRule);

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const forgotPasswordFormValidation = (forgotPasswordForm: IForgotPassword ): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const emailValidators: Array<Function> = [emailValidator, isRequired]; 

        let validationRule:IValidationRule = {
            validationRuleId: ForgotPasswordFormValidatorId.ForgotPasswordEmail,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: forgotPasswordForm.email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule); 

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const changePasswordFormValidation = (changePasswordForm: IChangePassword): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };

        const currentPasswordValidators: Array<Function> = [isRequired];
        const passwordValidators: Array<Function> = [passwordValidator, passwordComparatorValidator];
        const confirmedPasswordValdiators: Array<Function> = [passwordComparatorValidator];

        let validationRule:IValidationRule = {
            validationRuleId: ChangePasswordFormValidatorId.CurrentPassword,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: changePasswordForm.currentPassword,
            validators: currentPasswordValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ChangePasswordFormValidatorId.NewPassword,
            validationRuleType: FormValidatorRuleType.Comparator,
            isActive: true,
            mandatory: true,
            sourceValue: changePasswordForm.newPassword,
            comparatorValue: changePasswordForm.confirmPassword,
            comparatorRuleId: ChangePasswordFormValidatorId.ConfirmedPassword,
            validators: passwordValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ChangePasswordFormValidatorId.ConfirmedPassword,
            validationRuleType: FormValidatorRuleType.Comparator,
            isActive: true,
            mandatory: true,
            sourceValue: changePasswordForm.confirmPassword,
            comparatorValue: changePasswordForm.newPassword,
            comparatorRuleId: ChangePasswordFormValidatorId.NewPassword,
            validators: confirmedPasswordValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const resetPasswordFormValidation = (resetPasswordForm: IResetPassword): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
        const passwordValidators: Array<Function> = [passwordValidator, passwordComparatorValidator];
        const confirmedPasswordValdiators: Array<Function> = [passwordComparatorValidator];

        let validationRule:IValidationRule = {
            validationRuleId: ResetPasswordFormValidatorId.NewPassword,
            validationRuleType: FormValidatorRuleType.Comparator,
            isActive: true,
            mandatory: true,
            sourceValue: resetPasswordForm.newPassword,
            comparatorValue: resetPasswordForm.confirmPassword,
            comparatorRuleId: ResetPasswordFormValidatorId.ConfirmedPassword,
            validators: passwordValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ResetPasswordFormValidatorId.ConfirmedPassword,
            validationRuleType: FormValidatorRuleType.Comparator,
            isActive: true,
            mandatory: true,
            sourceValue: resetPasswordForm.confirmPassword,
            comparatorValue: resetPasswordForm.newPassword,
            comparatorRuleId: ResetPasswordFormValidatorId.NewPassword,
            validators: confirmedPasswordValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const newsletterSubscriptionFormValidation = (newsletterSubscription: INewsletterSubscription): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired]; 
        const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator]; 

        let validationRule:IValidationRule = {
            validationRuleId: SignupFormValidatorId.SignupEmail,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: newsletterSubscription.email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule); 
    
        validationRule = {
            validationRuleId: SignupFormValidatorId.Name,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: newsletterSubscription.name,
            validators: firstNameValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: SignupFormValidatorId.Surname,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: newsletterSubscription.surname,
            validators: surnameValdiators
        };
        validationRuleList.validationRules.push(validationRule);  

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const programmeShareFormValidation = (shareForm: IProgrammeShareForm): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired]; 
        const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator, isRequired];
        const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator, isRequired]; 
        const senderFirstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator, isRequired];
        const senderSurnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator, isRequired]; 

        let validationRule:IValidationRule = {
            validationRuleId: ShareProgrammeFormValidatorId.Email,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.Email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule); 
    
        validationRule = {
            validationRuleId: ShareProgrammeFormValidatorId.RecipientName,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.RecipientName,
            validators: firstNameValdiators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ShareProgrammeFormValidatorId.RecipientSurname,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.RecipientSurname,
            validators: surnameValdiators
        };
        validationRuleList.validationRules.push(validationRule);  

        validationRule = {
            validationRuleId: ShareProgrammeFormValidatorId.SenderName,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.SenderName,
            validators: senderFirstNameValdiators
        };
        validationRuleList.validationRules.push(validationRule); 

        validationRule = {
            validationRuleId: ShareProgrammeFormValidatorId.SenderSurname,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.SenderSurname,
            validators: senderSurnameValdiators
        };
        validationRuleList.validationRules.push(validationRule); 

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const applicationClosedShareFormValidation = (shareForm: IApplicationClosedForm): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator, isRequired];
        const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator, isRequired];
        const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired];  
        const contactNumberValidators: Array<Function> = [localAndInternationalPhoneNumberValidator, isRequired]; 

        let validationRule:IValidationRule = {
            validationRuleId: ApplicationClosedFormValidatorId.Email,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.Email,
            validators: emailValidators
        };
        validationRuleList.validationRules.push(validationRule); 

        validationRule = {
            validationRuleId: ApplicationClosedFormValidatorId.Name,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.Name,
            validators: firstNameValdiators
        };
        validationRuleList.validationRules.push(validationRule);
    
        validationRule = {
            validationRuleId: ApplicationClosedFormValidatorId.Surname,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.LastName,
            validators: surnameValdiators
        };
        validationRuleList.validationRules.push(validationRule);
    
        validationRule = {
            validationRuleId: ApplicationClosedFormValidatorId.ContactNumber,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: shareForm.ContactNumber,
            validators: contactNumberValidators
        };
        validationRuleList.validationRules.push(validationRule);

        validationRule = {
            validationRuleId: ApplicationClosedFormValidatorId.Message,
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: false,
            sourceValue: shareForm.Message 
        };
        validationRuleList.validationRules.push(validationRule);  

        const validation = new Validation(validationRuleList);
        return validation;
    }

    const formSectionValidation = ( formFieldList: Array<IFormSubProgrammeField>, reactiveForm: IFormReactiveForm): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        let validationRule:IValidationRule;
        formFieldList.forEach((formField: IFormSubProgrammeField) => {
            
            //If Dynamic section for WorkExperience exlcude these fields out for the validator
            if (formField.Atttribute !== 'gibs_totalnumberofyears' && formField.Atttribute !== 'gibs_totalnumberofyearsinmanagement' ) {
                // // Default Validation
                // let validatorFunction: Function | null;
                // switch (formField.ValidatorRules) {
                //     case 'notEmptyValidator':
                //         validatorFunction =  notEmptyValidator;
                //         break; 
                //     case 'emailValidator': 
                //         validatorFunction =  emailValidator;
                //         break;
                //     case 'isRequired': 
                //         validatorFunction =  isRequired;
                //         break;
                //     case 'isValidDate':
                //         validatorFunction =  isValidDate;
                //         break;
                //     case 'optionalValidator':
                //         validatorFunction =  optionalValidator;
                //         break;
                //     case 'isChecked':
                //         validatorFunction =  isChecked;
                //         break;
                //     case 'isValidPhoneNumber':
                //         validatorFunction = isValidPhoneNumber
                //         break;
                //     case 'alphaNumericValidator':
                //         validatorFunction = alphaNumericValidator
                //         break;
                //     case 'zaPostalCodeValidator':
                //         validatorFunction = zaPostalCodeValidator
                //         break;
                //     case 'isLegalTermsAccepted':
                //         validatorFunction = isLegalTermsAccepted
                //         break;
                //     case 'optionSelected': 
                //         validatorFunction = optionSelected
                //         break;
                //     case 'zaPhoneNumberValidator':
                //         validatorFunction = zaPhoneNumberValidator
                //         break;
                //     default: 
                //         validatorFunction = null;
                //         break;
                // }
                // let validator: Array<Function> =  (validatorFunction  != null)  ? [validatorFunction] : [];
            
                // Normal Validator rules
                let validatorRules = formField.ValidationRuleTemplateList.filter((validatorRule) => {
                    if (validatorRule.validationRuleType === FormValidatorRuleType.Validator){
                        return validatorRule;
                    }
                });

                if (validatorRules.length > 0) {
                    validationRuleList.validationRules = validationRuleList.validationRules.concat(validatorRules);
                }
                //Conditional Comparator Validations
                let conditionalRules = formField.ValidationRuleTemplateList.filter((validatorRule) => {
                    if (validatorRule.validationRuleType === FormValidatorRuleType.ConditionalComparator){
                        return validatorRule;
                    }
                });

                if (conditionalRules.length > 0) {
                        validationRuleList.validationRules = validationRuleList.validationRules.concat(conditionalRules);
                }

                //Interaction Validations
                let interactionRules = formField.ValidationRuleTemplateList.filter((validatorRule) => {
                    if (validatorRule.validationRuleType === FormValidatorRuleType.Interaction){
                        return validatorRule;
                    }
                });

                if (interactionRules.length > 0) {
                        validationRuleList.validationRules = validationRuleList.validationRules.concat(interactionRules);
                }
            }
            

        });

        // const titleValidators: Array<Function> =  [notEmptyValidator, isRequired];
        // const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired];
        // const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        // const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];

    
        // let validationRule:IValidationRule = {
        //     validationRuleId: FormGeneralSectionFormValidatorId.Title,
        //     mandatory: true,
        //     sourceValue: reactiveForm.gibs_title,
        //     validators: titleValidators
        // };
        // validationRuleList.validationRules.push(validationRule);
        
        // validationRule = {
        //     validationRuleId: ContactFormValidatorId.Email,
        //     mandatory: true,
        //     sourceValue: contactForm.email,
        //     validators: emailValidators
        // };
        // validationRuleList.validationRules.push(validationRule);
    
        // validationRule = {
        //     validationRuleId: ContactFormValidatorId.Name,
        //     mandatory: true,
        //     sourceValue: contactForm.name,
        //     validators: firstNameValdiators
        // };
        // validationRuleList.validationRules.push(validationRule);
    
        // validationRule = {
        //     validationRuleId: ContactFormValidatorId.Surname,
        //     mandatory: true,
        //     sourceValue: contactForm.surname,
        //     validators: surnameValdiators
        // };
        // validationRuleList.validationRules.push(validationRule);
        
        
        const validation = new Validation(validationRuleList);
    
        return validation;
    };

    const formWebFormValidation = (formFieldList: Array<IFormSubProgrammeField>, reactiveForm: IFormReactiveForm): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        let validationRule:IValidationRule;
        formFieldList.forEach((formField: IFormSubProgrammeField) => {

            if (typeof(formField.ValidatorRules) !== 'undefined' && formField != null) {                
                let validators: Array<Function> =  [];
                let validatorFunction: Function | null = null;

                // TODO: This logic exists in many places, extract as export from utils
                // TODO: @Neil - This is where we need to add the "other" other field overrides from blob storage
                // Create list of Conditional Comparator Validation Templates
                // if((typeof(formField.Sender) !== 'undefined' && formField.Sender != null)) {
                //     if(typeof(formField.Receiver) !== 'undefined' &&  formField.Receiver != null) {
                //         let conditionalComparator:IConditionalComparatorFunctionTemplate = {
                //             validationRuleId: formField.Atttribute,
                //             validationRuleType: FormValidatorRuleType.ConditionalComparator,
                //             sourceValue: '',
                //             comparatorRuleId: getGibsAttributeKey(formField.Sender),
                //             comparatorValue: '',
                //             conditionalValue: otherValueAttribute,
                //             interactionRuleId: formField.Atttribute
                //         }
                //         this.conditionalComparatorFunctionTemplateList.push(conditionalComparator);

                //         let interaction:IInteractionFunctionTemplate = {
                //             validationRuleId: formField.Atttribute,
                //             validationRuleType: FormValidatorRuleType.Interaction,
                //             sourceValue: '',
                //             comparatorRuleId: getGibsAttributeKey(formField.Sender),
                //             comparatorValue:'',
                //             interactionTrue: interactionTrueFunction,
                //             interactionFalse: interactionFalseFunction
                //         }
                //         this.interactionFunctionTemplateList.push(interaction);
                //     }
                // }

                // TODO: This logic exists in many places, extract as export from utils
                // FormValidator.DisplayAsMandatory means formField.ValidatorRules = 'isRequired'
                // even when formField.ValidatorRules == null
                if (formField.Validator === FormValidator.DisplayAsMandatory && formField.ValidatorRules == null) {
                    // ValidatorRules="isRequired" for Type:"Text" or 
                    // ValidatorRules="OptionSelected" for Type:"OptionSet" or Type:"Lookup" or 
                    // ValidatorRules="isChecked" for Type:"Boolean" or
                    // ValidatorRules="documentUploaded" for Type:"FileUpload" by default, then append additional Validator Functions from ValidatorRules property.
                    // add sane validators for missing mandatory field rule based on the formField.Type
                    switch (formField.Type?.trim()) {
                        case CRMControlType.Text:
                            formField.ValidatorRules = 'isRequired';
                            break;
                        case CRMControlType.OptionSet:
                        case CRMControlType.Lookup:
                            formField.ValidatorRules = 'optionSelected';
                            break;
                        case CRMControlType.Boolean:
                            formField.ValidatorRules = 'isChecked';
                            break;
                        case CRMControlType.FileUpload:
                            formField.ValidatorRules = 'documentUploaded';
                            break;
                        case CRMControlType.DateTime:
                            formField.ValidatorRules = 'isRequired|isValidDate';
                            break;
                        default:
                            formField.ValidatorRules = 'isRequired';
                            break;
                    }
                }

                // add sane validator for missing non-mandatory field rule based on the formField.Type
                if (formField.Validator === FormValidator.Display && formField.ValidatorRules == null) {
                    formField.ValidatorRules = 'optionalValidator';
                }

                // Now, sanity check validators and validator rules before applying them
                // TODO : Also cater for the case where the ValidatorRules are still inconsistent with the Validator
                //        FormValidator.DisplayAsMandatory has optionalValidator
                //        FormValidator.Display has isRequired, optionSelected, isChecked, documentUploaded, notEmptyValidator
                //        isLegalTermsAccepted is only for the Legal Section and is always present
                //
                //        Also, loop formField.ValidatorRules.split('|') and check for inconsistent/incongruent rules
                //        e.g. isRequired and notEmptyValidator
                //             isRequired and optionSelected
                //             isRequired and isChecked
                //             isRequired and documentUploaded
                //             isRequired and isLegalTermsAccepted
                //             optionSelected and isChecked
                //             optionSelected and documentUploaded
                //             optionSelected and isLegalTermsAccepted
                //             isChecked and documentUploaded
                //             isChecked and isLegalTermsAccepted
                //             documentUploaded and isLegalTermsAccepted
                //             notEmptyValidator and isLegalTermsAccepted
                //             notEmptyValidator and optionSelected
                //             notEmptyValidator and isChecked
                //             notEmptyValidator and documentUploaded
                //             notEmptyValidator and isRequired
                //             optionalValidator and isRequired
                //             optionalValidator and isLegalTermsAccepted
                //             optionalValidator and optionSelected
                //             optionalValidator and isChecked
                //             the list goes on...
                //
                //        To be tested: If FormValidator.Display and ValidatorRules is alphaNumericValidator, emailValidator,
                //                      numericValidator, zaPostalCodeValidator, zaPhoneNumberValidator or inputMaxLength160CharactersValidator
                //                      Then these only fire if the user inputs anything into the field

                let validatorRulesAttribute = formField.ValidatorRules?.split('|');

                validatorRulesAttribute?.forEach((validationRule) => {
                    switch (validationRule?.trim()) {
                        case 'notEmptyValidator':
                            // FormValidator.Display makes no sense as notEmptyValidator because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction =  notEmptyValidator;
                            }
                            break; 
                        case 'emailValidator': 
                            validatorFunction =  emailValidator;
                            break;
                        case 'isRequired': 
                        // FormValidator.Display makes no sense as isRequired because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction =  isRequired;
                            }
                            break;
                        case 'isValidDate':
                            validatorFunction =  isValidDate;
                            break;
                        case 'optionalValidator':
                            // FormValidator.DisplayAsMandatory makes no sense as optionalValidator because mandatory fields cannot be optional
                            if (formField.Validator === FormValidator.Display) {
                                validatorFunction =  optionalValidator;
                            }
                            break;
                        case 'isChecked':
                            // FormValidator.Display makes no sense as isChecked because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction =  isChecked;
                            }
                            break;
                        case 'isValidPhoneNumber':
                            validatorFunction = isValidPhoneNumber
                            break;
                        case 'alphaNumericValidator':
                            validatorFunction = alphaNumericValidator
                            break;
                        case 'zaPostalCodeValidator':
                            validatorFunction = zaPostalCodeValidator
                            break;
                        case 'isSouthAfricanId' :
                            validatorFunction = isSouthAfricanId;    
                            break;
                        case 'isLegalTermsAccepted':
                            validatorFunction = isLegalTermsAccepted
                            break;
                        case 'optionSelected':
                        case 'OptionSelected':
                            // FormValidator.Display makes no sense as optionSelected because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction = optionSelected;
                            }
                            break;
                        case 'zaPhoneNumberValidator':
                            validatorFunction = zaPhoneNumberValidator
                            break;
                        default: 
                            validatorFunction = null;
                            break;
                    }

                    if (validatorFunction != null) {
                        validators.push(validatorFunction);
                    }
                });

                validationRule = {
                    validationRuleId: formField.Atttribute,
                    validationRuleType: FormValidatorRuleType.Validator,
                    isActive: true,
                    mandatory: formField.Mandatory,
                    sourceValue: reactiveForm[formField.Atttribute], 
                    validators: validators
                };
                validationRuleList.validationRules.push(validationRule);
            }
        });
        
        const validation = new Validation(validationRuleList);
        return validation;
    }

    const formGeneralSectionFormValidation = (generalForm: IFormGeneralSectionFormCmd): Validation => {
    
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        const titleValidators: Array<Function> =  [notEmptyValidator, isRequired];
        const emailValidators: Array<Function> = [emailValidator, inputMaxLength320CharactersValidator, isRequired];
        const firstNameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];
        const surnameValdiators: Array<Function> = [unicodeNameValidator, inputMaxLength50CharactersValidator, notEmptyValidator];

    
        let validationRule:IValidationRule = {
            validationRuleId: FormGeneralSectionFormValidatorId.Title,
            isActive: true,
            mandatory: true,
            sourceValue: generalForm.gibs_title,
            validators: titleValidators
        };
        validationRuleList.validationRules.push(validationRule);
        
        // validationRule = {
        //     validationRuleId: ContactFormValidatorId.Email,
        //     mandatory: true,
        //     sourceValue: contactForm.email,
        //     validators: emailValidators
        // };
        // validationRuleList.validationRules.push(validationRule);
    
        // validationRule = {
        //     validationRuleId: ContactFormValidatorId.Name,
        //     mandatory: true,
        //     sourceValue: contactForm.name,
        //     validators: firstNameValdiators
        // };
        // validationRuleList.validationRules.push(validationRule);
    
        // validationRule = {
        //     validationRuleId: ContactFormValidatorId.Surname,
        //     mandatory: true,
        //     sourceValue: contactForm.surname,
        //     validators: surnameValdiators
        // };
        // validationRuleList.validationRules.push(validationRule);
        
        
        const validation = new Validation(validationRuleList);
    
        return validation;
    };

    const workXPFormValidation = (xpForm: IXPForm): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };

        const workExperienceValidators: Array<Function> = [numericValidator, isRequired];
        const managementExperienceValidators: Array<Function> = [numericValidator, isRequired];

        let validationRule:IValidationRule = {
            validationRuleId: 'gibs_totalnumberofyears',
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: xpForm['gibs_totalnumberofyears'],
            validators: workExperienceValidators
        };
        validationRuleList.validationRules.push(validationRule);

        let managementValidationRule:IValidationRule = {
            validationRuleId: 'gibs_totalnumberofyearsinmanagement',
            validationRuleType: FormValidatorRuleType.Validator,
            isActive: true,
            mandatory: true,
            sourceValue: xpForm['gibs_totalnumberofyearsinmanagement'],
            validators: managementExperienceValidators
        };
        validationRuleList.validationRules.push(managementValidationRule);

        const validation = new Validation(validationRuleList);
    
        return validation;
    };

    const dynamicWebFormValidation = (formFieldList: Array<IFormSubProgrammeField>, reactiveForm: z.infer<typeof FieldInfo>): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
    
        let validationRule:IValidationRule;

        formFieldList.forEach((formField: IFormSubProgrammeField) => {
            let reactiveField = reactiveForm.find(_ => _.id == formField.FieldId);

            if (reactiveField == null){
                return;
            }

            if (reactiveField != null && reactiveField.isShown == false)
            {
                return;
            }
            // TODO: This logic exists in many places, extract as export from utils
            // TODO: @Neil - This is where we need to add the "other" other field overrides from blob storage
            // Create list of Conditional Comparator Validation Templates
            // if((typeof(formField.Sender) !== 'undefined' && formField.Sender != null)) {
            //     if(typeof(formField.Receiver) !== 'undefined' &&  formField.Receiver != null) {
            //         let conditionalComparator:IConditionalComparatorFunctionTemplate = {
            //             validationRuleId: formField.Atttribute,
            //             validationRuleType: FormValidatorRuleType.ConditionalComparator,
            //             sourceValue: '',
            //             comparatorRuleId: getGibsAttributeKey(formField.Sender),
            //             comparatorValue: '',
            //             conditionalValue: otherValueAttribute,
            //             interactionRuleId: formField.Atttribute
            //         }
            //         this.conditionalComparatorFunctionTemplateList.push(conditionalComparator);

            //         let interaction:IInteractionFunctionTemplate = {
            //             validationRuleId: formField.Atttribute,
            //             validationRuleType: FormValidatorRuleType.Interaction,
            //             sourceValue: '',
            //             comparatorRuleId: getGibsAttributeKey(formField.Sender),
            //             comparatorValue:'',
            //             interactionTrue: interactionTrueFunction,
            //             interactionFalse: interactionFalseFunction
            //         }
            //         this.interactionFunctionTemplateList.push(interaction);
            //     }
            // }

            // TODO: This logic exists in many places, extract as export from utils
            // FormValidator.DisplayAsMandatory means formField.ValidatorRules = 'isRequired'
            // even when formField.ValidatorRules == null
            if (formField.Validator === FormValidator.DisplayAsMandatory && formField.ValidatorRules == null) {
                // ValidatorRules="isRequired" for Type:"Text" or 
                // ValidatorRules="OptionSelected" for Type:"OptionSet" or Type:"Lookup" or 
                // ValidatorRules="isChecked" for Type:"Boolean" or
                // ValidatorRules="documentUploaded" for Type:"FileUpload" by default, then append additional Validator Functions from ValidatorRules property.
                // add sane validators for missing mandatory field rule based on the formField.Type
                switch (formField.Type?.trim()) {
                    case CRMControlType.Text:
                        formField.ValidatorRules = 'isRequired';
                        break;
                    case CRMControlType.OptionSet:
                    case CRMControlType.Lookup:
                        formField.ValidatorRules = 'optionSelected';
                        break;
                    case CRMControlType.Boolean:
                        formField.ValidatorRules = 'isChecked';
                        break;
                    case CRMControlType.FileUpload:
                        formField.ValidatorRules = 'documentUploaded';
                        break;
                    case CRMControlType.DateTime:
                        formField.ValidatorRules = 'isRequired|isValidDate';
                        break;
                    default:
                        formField.ValidatorRules = 'isRequired';
                        break;
                }
            }

            // add sane validator for missing non-mandatory field rule based on the formField.Type
            if (formField.Validator === FormValidator.Display && formField.ValidatorRules == null) {
                formField.ValidatorRules = 'optionalValidator';
            }

            // Now, sanity check validators and validator rules before applying them
            // TODO : Also cater for the case where the ValidatorRules are still inconsistent with the Validator
            //        FormValidator.DisplayAsMandatory has optionalValidator
            //        FormValidator.Display has isRequired, optionSelected, isChecked, documentUploaded, notEmptyValidator
            //        isLegalTermsAccepted is only for the Legal Section and is always present
            //
            //        Also, loop formField.ValidatorRules.split('|') and check for inconsistent/incongruent rules
            //        e.g. isRequired and notEmptyValidator
            //             isRequired and optionSelected
            //             isRequired and isChecked
            //             isRequired and documentUploaded
            //             isRequired and isLegalTermsAccepted
            //             optionSelected and isChecked
            //             optionSelected and documentUploaded
            //             optionSelected and isLegalTermsAccepted
            //             isChecked and documentUploaded
            //             isChecked and isLegalTermsAccepted
            //             documentUploaded and isLegalTermsAccepted
            //             notEmptyValidator and isLegalTermsAccepted
            //             notEmptyValidator and optionSelected
            //             notEmptyValidator and isChecked
            //             notEmptyValidator and documentUploaded
            //             notEmptyValidator and isRequired
            //             optionalValidator and isRequired
            //             optionalValidator and isLegalTermsAccepted
            //             optionalValidator and optionSelected
            //             optionalValidator and isChecked
            //             the list goes on...
            //
            //        To be tested: If FormValidator.Display and ValidatorRules is alphaNumericValidator, emailValidator,
            //                      numericValidator, zaPostalCodeValidator, zaPhoneNumberValidator or inputMaxLength160CharactersValidator
            //                      Then these only fire if the user inputs anything into the field
            
            if (typeof(formField.ValidatorRules) !== 'undefined' && formField != null) {
                
                let validators: Array<Function> =  [];
                let validatorFunction: Function | null = null;
                let validatorRulesAttribute = formField.ValidatorRules?.split('|');

                validatorRulesAttribute?.forEach((validationRule) => {
                    switch (validationRule?.trim()) {
                        case 'notEmptyValidator':
                            // FormValidator.Display makes no sense as notEmptyValidator because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction =  notEmptyValidator;
                            }
                            break; 
                        case 'emailValidator': 
                            validatorFunction =  emailValidator;
                            break;
                        case 'isRequired':
                            // FormValidator.Display makes no sense as isRequired because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction =  isRequired;
                            }
                            break;
                        case 'isValidDate':
                            validatorFunction =  isValidDate;
                            break;
                        case 'optionalValidator':
                            // FormValidator.DisplayAsMandatory makes no sense as optionalValidator because mandatory fields cannot be optional
                            if (formField.Validator === FormValidator.Display) {
                                validatorFunction =  optionalValidator;
                            }
                            break;
                        case 'isChecked':
                            // FormValidator.Display makes no sense as isChecked because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction =  isChecked;
                            }
                            break;
                        case 'isValidPhoneNumber':
                            validatorFunction = isValidPhoneNumber;
                            break;
                        case 'alphaNumericValidator':
                            validatorFunction = alphaNumericValidator;
                            break;
                        case 'zaPostalCodeValidator':
                            validatorFunction = zaPostalCodeValidator;
                            break;
                        case 'isLegalTermsAccepted':
                            validatorFunction = isLegalTermsAccepted;
                            break;
                        case 'optionSelected':
                        case 'OptionSelected':
                            // FormValidator.Display makes no sense as optionSelected because optional fields cannot be mandatory
                            if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                validatorFunction = optionSelected;
                            }
                            break;
                        case 'zaPhoneNumberValidator':
                            validatorFunction = zaPhoneNumberValidator;
                            break;
                        case 'isSouthAfricanId' :
                            validatorFunction = isSouthAfricanId;
                            break;
                        default: 
                            validatorFunction = null;
                            break;
                    }

                    if(validatorFunction  != null){
                        validators.push(validatorFunction);
                    }
                });
                
                let matchingItems = reactiveForm.filter((fieldInfos) => {
                    if (fieldInfos.entityMappings != null)
                    {
                        return fieldInfos.entityMappings.some((entityMapping) => {
                            return entityMapping.attribute === formField.Atttribute;
                        });
                    }
                    else{
                        return false;
                    }
                });

                let matchingItem = (typeof (matchingItems) !== 'undefined' && matchingItems?.length > 0)
                    ? matchingItems[0] : null;

                validationRule = {
                    validationRuleId: formField.FieldId,
                    validationRuleType: FormValidatorRuleType.Validator,
                    isActive: true,
                    mandatory: formField.Mandatory && formField.Validator === FormValidator.DisplayAsMandatory,
                    sourceValue: (typeof(matchingItem) !== 'undefined') ? matchingItem?.value?.toString() || '' : '', 
                    validators: validators
                };
                //debugger;
                validationRuleList.validationRules.push(validationRule);
            }
        });
        
        const validation = new Validation(validationRuleList);
        return validation;
    }

    const abortApplicationValidation = (abortData: IAbortApplication): Validation => {
        const validationRuleList: IValidationRuleList = {
            validationRules: new Array<IValidationRule>()
        };
        const validation = new Validation(validationRuleList);
    
        return validation;
    };

    return { 
        contactFormValidation,
        loginFormValidation,
        subscriptionFormValidation: signupFormValidation,
        changePasswordFormValidation,
        forgotPasswordFormValidation,
        resetPasswordFormValidation,
        newsletterSubscriptionFormValidation,
        applicationClosedShareFormValidation,
        programmeShareFormValidation,
        formGeneralSectionFormValidation,
        formSectionValidation,
        abortApplicationValidation,
        workXPFormValidation,
        formWebFormValidation,
        dynamicWebFormValidation
    };
}

