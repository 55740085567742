<template>
	<div class="form-group" :class="{'hide': (!props.show)}">
		<label :for="inputId" class="contact-form-label">{{ labelText }}
			<span v-if="!props.isRequired" class="optional-text"> (optional)</span>
		</label>
		<div class="password-input-container">
			<input v-if="props.inputId.includes('password') || props.inputId.includes('Password')"  
				class="contact-form-input password-input absolute"  :class="{'disabled': props.isDisabled}"
				type="password" 
				:placeholder="placeholderText" 
				:value="props.modelValue"
				:id="inputId"
				:readonly=" props.isDisabled"
				:maxlength="props.maxLength"
				@input="handleInput" 
			/>
			<input v-else 
				class="contact-form-input"   :class="{'disabled': props.isDisabled}"
				:type="props.type" 
				:id="inputId" 
				:placeholder="placeholderText" 
				:value="props.modelValue" 
				:readonly=" props.isDisabled"
				:maxlength="props.maxLength"
				@input="handleInput" 
			/>

			<!-- TODO: need to refactor this to be generic instead of trying to get a specific ID -->
			<div class="password-visibility-container" v-if="props.inputId.includes('password') || props.inputId.includes('Password')">
				<span class="password-toggle-icon" @click="togglePasswordVisibility">
					<NuxtLink class="mobile-search-icon">
						<nuxt-icon v-if="showPassword == false" name="password-icon-hidden" />
						<nuxt-icon v-else name="password-icon-show" />
					</NuxtLink>
				</span>
			</div>
		</div>
		<p :id="`${inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>

		<!-- TODO: refactor this by using one variable -->
		<div v-if="props.inputId.includes('password')" class="gibs-password-hint">
			<nuxt-icon name="gibs-hint-info" class="gibs-input-hint-icon"/>
			<p class="gibs-password-hint-text">
				<strong>Note:</strong> The password must contain a
				<strong>minimum of 8 characters</strong> with
				<strong>1 Uppercase, 1 Lowercase, 1 Number</strong>, and
				<strong>1 Special character.</strong>
			</p>
		</div>
		<p v-else class="hint-text">{{ hint }}</p>

	</div>
</template>

<script setup lang="ts">

const props = defineProps({
	type: {
		type: String,
		required: true,
	},
	inputId: {
		type: String,
		required: true,
	},
	labelText: {
		type: String,
		required: true,
	},
	placeholderText: {
		type: String,
		default: '',
	},
	hint: {
		type: String,
		default: '',
	},
	isRequired: {
		type: Boolean,
		default: false,
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
	modelValue: {
		type: [String, Number, Object],
		default: '',
	},
	validator: {
		type: Validation,
		default: null,
	},
	validatorRuleId: {
		type: String,
		default: null
	},
	show: {
		type: Boolean,
		default: true
	},
	doValidation :
	{
		type: Boolean,
		default: false
	},
	maxLength :{
		type : Number,
		default : 1024
	}
});
defineExpose({
	ApplyValidate
});

const inputValue = ref('');
const showPassword = ref(false);

const handleInput = (event: InputEvent) => {
	// if (event.currentTarget.id == 'gibs_totalnumberofyearsinmanagement') {

	// }
	event.stopPropagation();
	inputValue.value = (event.target as HTMLInputElement).value;

	if (typeof (props.validator) !== 'undefined' && props.validator != null) {
		props.validator.setValidationRuleSourceValue(props.validatorRuleId, inputValue.value);
		let resultList = props.validator.validateRule(props.validatorRuleId);

		ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
	}

	emitFormInput('input', inputValue.value , event);
};

const emitFormInput = defineEmits<{
	input: [email: string, event: InputEvent]
}>();

// Init();

// function Init(){
// 	console.log("Init : form-input ["+props.inputId+"]");
// }

function togglePasswordVisibility() {
	const input: HTMLInputElement = document.getElementById(props.inputId) as HTMLInputElement;
	if (input) {
		input.type = showPassword.value ? 'password' : 'text';
		showPassword.value = !showPassword.value;
	}
}

function ApplyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
	const inputElement = document.getElementById(props.inputId) as HTMLElement;
	const errorElement = document.getElementById(
		`${props.inputId}-error`
	) as HTMLElement;

	if (isInvalid) {
		inputElement.classList.add("has-error");
	}
	else {
		inputElement.classList.remove("has-error");
	}

	let errorString = '';
	if (typeof (props.validator) !== 'undefined' && props.validator != null) {
		errorString = props.validator.getValidationResultString(props.validatorRuleId);
	}
	errorElement.innerText = errorString;
}
onMounted(async () => {
	if (props.doValidation){
		if (typeof (props.validator) !== 'undefined' && props.validator != null) {
			props.validator.setValidationRuleSourceValue(props.validatorRuleId, props.modelValue);
			let resultList = props.validator.validateRule(props.validatorRuleId);

			ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
		}
	}
});
</script>
